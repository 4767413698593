import api from "./apiToken";

class RfidService {
  getRFID() {
    return api.get("/getRfid");
  }
  deleteRFID(id) {
    return api.delete(`/deleteRFID/${id}`).then(() => location.reload());
  }
  deleteRFIDBulk(data) {
    return api.delete(`/rfid/bulk-delete`, { data });
  }
}

export default new RfidService();
