<template>
  <v-data-table
    :headers="headers"
    :items="dataVerify"
    :show-select="bulkAction"
    v-model="selectedProduk"
    class="elevation-1"
    sort-by="sku"
    item-key="id"
    :loading="tableLoading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-card v-if="BEFORE" class="px-12 py-4" rounded="xl">
          <div class="d-flex flex-column align-center mb-4">
            <v-img
              src="@/assets/confirmassets.svg"
              alt="assets"
              width="250"
            ></v-img>
          </div>
          <div class="text-h6 mb-4">Kamu yakin produk ini hilang ?</div>
          <div class="mb-4">
            <v-radio-group v-model="radios">
              <v-row>
                <v-col cols="6">
                  <v-radio value="Hilang" @click="resetCabang">
                    <template v-slot:label>
                      <div>
                        <strong class="black--text">Hilang</strong>
                      </div>
                    </template>
                  </v-radio>
                </v-col>
                <v-col cols="6">
                  <v-radio value="Perbaikan" @click="resetCabang">
                    <template v-slot:label>
                      <div>
                        <strong class="black--text">Services</strong>
                      </div>
                    </template>
                  </v-radio>
                </v-col>
                <v-col cols="12">
                  <v-radio value="Pindah Cabang" @click="resetCabang">
                    <template v-slot:label>
                      <div>
                        <strong class="black--text"
                          >Pindah Cabang / Tersedia</strong
                        >
                      </div>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </div>
          <div v-if="radios === 'Pindah Cabang'">
            <v-select
              v-model="cabang"
              label="Cabang"
              :items="selectCabang"
              item-text="branch_name"
              item-value="id"
            >
            </v-select>
          </div>
          <div>
            <v-text-field v-model="notes" label="Notes"></v-text-field>
          </div>
          <v-card-actions class="d-flex justify-end">
            <v-btn @click="closeEdit" outlined color="myPrimary white--text"
              >Kembali</v-btn
            >
            <v-btn @click="saveEdit" color="myPrimary white--text"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card v-if="AFTER" class="px-12 py-4" rounded="xl">
          <div class="d-flex flex-column align-center mb-4">
            <v-img
              src="@/assets/confirmassets.svg"
              alt="assets"
              width="250"
            ></v-img>
          </div>
          <div class="text-h6 mb-4">Konfirmasi Barang</div>
          <v-card-actions class="d-flex justify-end">
            <v-btn @click="closeEdit" outlined color="myPrimary white--text"
              >Kembali</v-btn
            >
            <v-btn @click="saveEdit" color="myPrimary white--text"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPreviewGold" max-width="800px">
        <PreviewModal
          :data="showedData"
          @closePreview="closePreview"
          :dontShowButton="true"
          :emas="true"
        />
      </v-dialog>
      <v-dialog v-model="dialogPreviewBerlian" max-width="800px">
        <PreviewModal
          :data="showedData"
          @closePreview="closePreview"
          :dontShowButton="true"
          :berlian="true"
        />
      </v-dialog>
      <v-dialog
        v-if="isUpdateChoosed"
        v-model="dialogBulkUpdate"
        max-width="550px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="pa-2">
            <v-btn
              small
              color="blue white--text"
              class="mr-2 mb-3"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-update</v-icon>Update Produk
            </v-btn>
          </div>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            Apakah anda yakin ingin update produk ini ?</v-card-title
          >
          <form>
            <div class="px-5">
              <v-select
                v-model="newDataRfid.cabang"
                label="Cabang"
                :items="selectCabang"
                item-text="branch_name"
                item-value="id"
                @input="getCabang"
              >
              </v-select>
            </div>
            <div class="px-5">
              <v-textarea
                v-model="newDataRfid.catatan"
                label="Note"
              ></v-textarea>
            </div>
          </form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialogFalse = false">
              TIDAK
            </v-btn>
            <v-btn color="green darken-1" text @click="updateBulkProduk">
              YA
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-btn
        :color="getColor(item.status)"
        dark
        x-small
        @click="editItem(item)"
      >
        {{ item.status }}
      </v-btn>
    </template>
    <template v-slot:[`item.detail`]="{ item }">
      <v-btn x-small class="mr-2" @click="previewItem(item)"> Lihat </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import PreviewModal from "../Modal/PreviewModal.vue";
import verificationService from "../../services/verification.service";
import dataService from "../../services/data.service";
// import StandartModal from "../Modal/StandartModal";
import { Verify } from "../../data/verify";

export default {
  props: ["BEFORE", "AFTER", "bulkAction", "isUpdateChoosed"],
  components: {
    PreviewModal,
    // StandartModal,
  },
  data() {
    return {
      tableLoading: false,

      dialogPreviewGold: false,
      dialogPreviewBerlian: false,
      dialogEdit: false,

      showedData: {},

      // modal action
      dialogBulkUpdate: false,

      // table action
      selectedProduk: [],
      selectCabang: [],

      radios: "",
      cabang: "",
      notes: "",

      dataVerify: [],
      editedIndex: -1,
      headers: Verify,

      newDataRfid: {
        catatan: "",
        cabang: "",
      },
    };
  },
  watch: {
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogPreviewGold(val) {
      val || this.closePreview();
    },
    dialogPreviewBerlian(val) {
      val || this.closePreview();
    },
    dialogBulkUpdate(val) {
      val || this.closeEdit();
    },
  },

  methods: {
    async fetchData() {
      if (this.BEFORE) {
        this.tableLoading = true;
        await verificationService.getBeforeVerify().then((res) => {
          this.dataVerify = res.data;
          this.tableLoading = false;
        });
      }

      if (this.AFTER) {
        this.tableLoading = true;

        await verificationService.getAfterVerify().then((res) => {
          this.dataVerify = res.data;
          this.tableLoading = false;
        });
      }
    },

    async getCabang() {
      await dataService.getCabang().then((res) => {
        this.selectCabang = res.data;
      });
    },

    // modal action
    async updateBulkProduk() {
      const data = {
        id: this.selectedProduk.map((a) => a.id),
        note: this.newDataRfid.catatan,
        branch_id: this.newDataRfid.cabang,
      };

      await dataService.bulkUpdate(data).then(() => {
        location.reload();
      });

      this.dialogBulkUpdate = false;
    },

    resetCabang() {
      this.cabang = "";
    },

    getColor(status) {
      if (status == "Hilang") return "red";
      else if (status == "Perbaikan") return "orange";
      else return "blue";
    },

    previewItem(item) {
      this.editedIndex = this.dataVerify.indexOf(item);
      this.showedData = Object.assign({}, item);

      if (this.showedData.stone_id === 1) {
        this.dialogPreviewGold = true;
      }
      if (this.showedData.stone_id === 2) {
        this.dialogPreviewBerlian = true;
      }
    },
    closePreview() {
      this.dialogPreviewGold = false;
      this.dialogPreviewBerlian = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.editedIndex = this.dataVerify.indexOf(item);
      this.showedData = Object.assign({}, item);
      this.dialogEdit = true;
    },

    closeEdit() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveEdit() {
      const id = this.showedData.product_id;

      if (this.BEFORE) {
        const data = {
          status: this.radios,
          branch_id: this.cabang,
          note: this.notes,
        };

        verificationService.postChangeVerify(id, data).then(() => {
          location.reload();
        });
      }

      if (this.AFTER) {
        const data = {
          verif_id: id,
        };

        verificationService.postConfirmVerify(data).then(() => {
          location.reload();
        });
      }

      this.closeEdit();
    },
  },

  created() {
    this.fetchData();
    this.getCabang();
  },
};
</script>
