<template>
  <v-card elevation="0" :loading="loading">
    <div class="px-4 py-2">
      <div class="d-flex align-center">
        <div>
          <v-icon class="blue lighten-4 blue--text pa-1 rounded-sm" size="15">
            mdi-archive-outline
          </v-icon>
        </div>
        <div class="text-body-2 ml-5">{{ header }}</div>
      </div>
      <v-divider class="mt-2"></v-divider>
      <div class="mt-2 text-center text-h6">{{ quantity }} {{ satuan }}</div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["header", "quantity", "satuan", "loading"],
};
</script>
