<template>
  <TemplateHeader :header="'List RFID'">
    <TableRFID :bulkAction="true" :isDeleteChoosed="true" />
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../layouts/TemplateHeader.vue";
import TableRFID from "../../components/Table/TableRFID.vue";

export default {
  components: {
    TemplateHeader,
    TableRFID,
  },
};
</script>
