<template>
  <div class="myHeight ma-0 myBrokenWhite pa-4">
    <div class="d-flex justify-space-between">
      <div class="text-h5">Verification</div>
    </div>
    <v-divider class="mt-4"></v-divider>

    <div class="white gray--text py-4 text-caption">
      Silahkan verifikasi perpindahan produk antar cabang
    </div>

    <v-divider class="mb-4"></v-divider>

    <v-row>
      <v-col cols="12" md="6">
        <div class="text-center text-h6 pb-4">Menunggu Verifikasi</div>
        <TableVerify
          :BEFORE="true"
          :bulkAction="true"
          :isUpdateChoosed="true"
        />
      </v-col>

      <v-divider vertical class="mt-4"></v-divider>

      <v-col cols="12" md="6">
        <div class="text-center text-h6 pb-4">Sudah Terverifikasi</div>
        <TableVerify :AFTER="true" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TableVerify from "../../components/Table/TableVerify.vue";

export default {
  components: {
    TableVerify,
  },
};
</script>

<style scoped>
.btn {
  text-transform: none;
}

.myHeight {
  min-height: 100vh;
}
</style>
