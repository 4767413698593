var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TemplateHeader',{attrs:{"header":'List RFID'}},[_c('v-card',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"mb-10"},[_c('div',{staticClass:"font-weight-medium"},[_vm._v("Harga Emas")])])]),(!_vm.goldPrice)?_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"d-flex justify-space-between align-center",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Harga Emas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Harga Emas","error-messages":errors,"rules":[_vm.numberRule],"prefix":"Rp."},model:{value:(_vm.goldPriceValue),callback:function ($$v) {_vm.goldPriceValue=$$v},expression:"goldPriceValue"}})]}}],null,true)}),_c('v-btn',{staticClass:"btn",attrs:{"color":"myPrimary white--text","disabled":invalid},on:{"click":_vm.postGoldPrice}},[_vm._v("Buat Harga")])],1)]}}],null,false,796540696)})],1):_vm._e(),(_vm.goldPrice)?_c('div',{staticClass:"mt-2"},[(_vm.EDIT)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"d-flex justify-space-between align-center",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Harga Emas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Harga Emas","error-messages":errors,"rules":[_vm.numberRule],"prefix":"Rp."},model:{value:(_vm.goldPriceValue),callback:function ($$v) {_vm.goldPriceValue=$$v},expression:"goldPriceValue"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-btn',{staticClass:"btn mr-2",attrs:{"color":"myRed white--text"},on:{"click":function($event){_vm.EDIT = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"btn",attrs:{"color":"myPrimary white--text","loading":_vm.loading,"disabled":invalid},on:{"click":_vm.updateGoldPrice}},[_vm._v("Confirm")])],1)],1)]}}],null,false,936938622)}):_vm._e(),(!_vm.EDIT)?_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"font-weight-medium text-h5"},[_vm._v(_vm._s(_vm.goldPrice.price))]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-btn',{staticClass:"btn",attrs:{"color":"myPrimary white--text"},on:{"click":function($event){_vm.EDIT = true}}},[_vm._v("Update")])],1)]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }