<template>
  <v-card>
    <v-row>
      <v-col cols="6">
        <v-card-title>
          <v-text-field
            v-model="searchRFID"
            append-icon="mdi-magnify"
            label="Pencarian"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-col>
      <v-col class="align-self-center">
        <v-dialog v-model="dialogImport" width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              color="myPrimary"
              class="mr-2 mb-3"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-plus</v-icon>Impor Data
            </v-btn>
          </template>
          <ImportModal :RFID="true" @closeImporData="closeImporData" />
        </v-dialog>
        <v-dialog
          v-if="isDeleteChoosed"
          v-model="dialogDeleteChoosed"
          max-width="550px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="red white--text"
              class="mr-2 mb-3"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left> mdi-delete</v-icon>Hapus Pilihan
            </v-btn>
          </template>
          <StandartModal
            :text="'Apakah anda yakin ingin menghapus RFID ?'"
            @dialogFalse="dialogDeleteChoosed = false"
            @dialogTrue="deleteSelectedRfid"
          />
        </v-dialog>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headersRFID"
      :items="dataRFID"
      :show-select="bulkAction"
      v-model="selectedRfid"
      :search="searchRFID"
      item-key="rfid"
      class="elevation-1"
      calculate-widths
      :loading="tableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="550px">
          <v-card>
            <v-card-title class="text-h5">
              Apakah Anda yakin untuk menghapus RFID?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="dialogDelete = false">
                TIDAK
              </v-btn>
              <v-btn color="green darken-1" text @click="confirmDelete">
                YA
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.number`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ImportModal from "../Modal/ImportModal.vue";
import rfidService from "../../services/rfid.service";
import StandartModal from "../Modal/StandartModal";
// import dataService from "../../services/data.service";

export default {
  props: [
    "isDeleteChoosed",
    // table action
    "bulkAction",
  ],

  components: {
    ImportModal,
    StandartModal,
  },
  data() {
    return {
      tableLoading: false,

      // modal action
      dialogDeleteChoosed: false,

      dialogImport: false,
      dialogDelete: false,
      deletedIndex: -1,
      deletedDataRFID: {},
      dataRFID: [],
      searchRFID: "",

      // table action
      selectedRfid: [],
      headersRFID: [
        { text: "No.", value: "number", sortable: false, width: "50px" },
        { text: "RFID", value: "rfid", sortable: false },
        { text: "Delete", value: "actions", sortable: false, align: "right" },
      ],
    };
  },
  methods: {
    closeImporData() {
      this.dialogImport = false;
    },
    async getRFID() {
      this.tableLoading = true;

      await rfidService.getRFID().then((res) => {
        this.dataRFID = res.data;
        this.tableLoading = false;
      });
    },
    deleteItem(item) {
      this.deletedIndex = this.dataRFID.indexOf(item);
      this.deletedDataRFID = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async confirmDelete() {
      const id = this.deletedDataRFID.id;

      await rfidService.deleteRFID(id);
    },

    // modal action delete bulk RFID
    async deleteSelectedRfid() {
      const data = {
        rfid: this.selectedRfid.map((a) => a.rfid),
      };

      await rfidService
        .deleteRFIDBulk(data)
        .then(() => {
          // location.reload();
          this.getRFID();
        })
        .catch((err) => {
          console.log(err.response);
        });

      this.dialogDeleteChoosed = false;
    },
  },
  created() {
    this.getRFID();
  },
};
</script>
