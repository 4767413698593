<template>
  <TemplateHeader :header="'Dashboard'">
    <v-row>
      <v-col cols="12" md="9">
        <div class="font-weight-medium text-body-1">Statistik Data Pusat</div>
        <v-alert v-if="showError" closable text type="error">{{ errorMsg }}</v-alert>
        <v-row class="mt-8">
          <v-col cols="6" md="3" class="clickable" @click="toSold">
            <InfoSmallCard
              :header="'Produk Terjual'"
              :quantity="dataTerjual.produk_terjual"
              :satuan="'Produk'"
              :loading="cardLoadingTerjual"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toVerification">
            <InfoSmallCard
              :header="'Produk Hilang'"
              :quantity="dataTerjual.produk_hilang"
              :satuan="'Produk'"
              :loading="cardLoadingTerjual"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toBerlianTerjual">
            <InfoSmallCard
              :header="'Berlian Terjual'"
              :quantity="dataDetailBerlian.berlian_terjual"
              :satuan="'Produk'"
              :loading="cardLoadingDetailBerlian"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toEmasTerjual">
            <InfoSmallCard
              :header="'Emas Terjual'"
              :quantity="dataDetailEmas.emas_terjual"
              :satuan="'gram'"
              :loading="cardLoadingDetailEmas"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toBerlianTersedia">
            <InfoSmallCard
              :header="'Stok Berlian'"
              :quantity="dataDetailBerlian.berlian_tersedia"
              :satuan="'Produk'"
              :loading="cardLoadingDetailBerlian"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toEmasTersedia">
            <InfoSmallCard
              :header="'Stok Emas'"
              :quantity="dataDetailEmas.emas_tersedia"
              :satuan="'gram'"
              :loading="cardLoadingDetailEmas"
            />
          </v-col>
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Keuntungan Emas'"
              :quantity="dataDetailEmas.keuntungan_emas"
              :satuan="'gram'"
              :loading="cardLoadingDetailEmas"
            />
          </v-col>
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Keuntungan Berlian'"
              :quantity="parseRupiah(dataDetailBerlian.keuntungan_berlian)"
              :loading="cardLoadingDetailBerlian"
            />
          </v-col>
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Emas Ikat Berlian'"
              :quantity="dataDetailEmas.berat_emas_terikat"
              :satuan="'gram'"
              :loading="cardLoadingDetailEmas"
            />
          </v-col>
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Harga Berlian Terikat'"
              :quantity="parseRupiah(dataDetailBerlian.harga_berlian_terikat)"
              :satuan="''"
              :loading="cardLoadingDetailBerlian"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card height="auto" :loading="cardLoadingTerjualBaru">
              <div class="pa-4">
                <div class="d-flex justify-space-between align-center mb-4">
                  <div>Pesanan Terbaru</div>
                  <div>
                    <v-btn to="/sold" small color="mySecondary white--text btn"> Lihat Lainnya </v-btn>
                  </div>
                </div>
                <div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Tanggal</th>
                          <th class="text-left">RFID</th>
                          <th class="text-left">Kode Barang</th>
                          <th class="text-left">Nama Produk</th>
                          <th class="text-left">Cabang</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in dataTerjualBaru" :key="item.rfid">
                          <td>{{ item.date_order }}</td>
                          <td>{{ item.rfid }}</td>
                          <td>{{ item.sku }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.branch.branch_name }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <TablePenjualan />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="3">
        <v-card elevation="0" :loading="cabangLoading">
          <div class="pa-4">
            <div class="font-weight-medium text-body-1">Penjualan Cabang</div>

            <v-card
              v-for="cabang in dataCabang"
              :key="cabang.id"
              :to="'/dashboard/' + cabang.id"
              link
              class="pa-2 my-4"
            >
              <div class="d-flex align-center py-2">
                <div>
                  <v-icon class="blue myPrimary white--text pa-1 rounded-sm" size="15"> mdi-store-outline </v-icon>
                </div>
                <div class="text-body-2 font-weight-medium ml-4">
                  {{ cabang.branch_name }}
                </div>
              </div>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import InfoSmallCard from "../../../components/Card/InfoSmallCard.vue";

import dataService from "../../../services/data.service";

import TablePenjualan from "../../../components/Table/TablePenjualan.vue";

export default {
  components: {
    InfoSmallCard,
    TablePenjualan,
    TemplateHeader,
  },
  data() {
    return {
      cardLoadingTerjualBaru: false,
      cardLoadingTerjual: false,
      cardLoadingDetailBerlian: false,
      cardLoadingDetailEmas: false,
      cabangLoading: false,
      showError: false,
      errorMsg: "",

      dataTerjualBaru: [],
      dataTerjual: {
        produk_terjual: 0,
        produk_hilang: 0,
      },
      dataDetailBerlian: {
        berlian_terjual: 0,
        berlian_tersedia: 0,
        harga_berlian_terikat: 0,
        keuntungan_berlian: 0,
      },
      dataDetailEmas: {
        berat_emas_terikat: 0,
        emas_terjual: 0,
        emas_tersedia: 0,
        keuntungan_emas: 0,
      },
      dataCabang: [],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" s/d ");
    },
  },
  methods: {
    toSold() {
      this.$router.push("/sold");
    },
    toVerification() {
      this.$router.push("/verification");
    },
    toEmasTerjual() {
      this.$router.push("/sold-gold");
    },
    toBerlianTerjual() {
      this.$router.push("/sold-diamond");
    },
    toEmasTersedia() {
      this.$router.push("/stock-gold");
    },
    toBerlianTersedia() {
      this.$router.push("/stock-diamond");
    },
    async getProductSoldNew() {
      try {
        this.cardLoadingTerjualBaru = true;

        const soldNew = await dataService.getProductSoldNew();

        this.dataTerjualBaru = soldNew.data.data;
        this.cardLoadingTerjualBaru = false;
      } catch (error) {
        this.showError = true;
        this.errorMsg = error.message;

        setTimeout(() => {
          this.showError = false;
          this.errorMsg = "";
        }, 3000);
      }
    },

    async getProductSoldLost() {
      try {
        this.cardLoadingTerjual = true;

        const soldLost = await dataService.getProductSoldLost();

        this.dataTerjual = soldLost.data.data;
        this.cardLoadingTerjual = false;
      } catch (error) {
        this.showError = true;
        this.errorMsg = error.message;

        setTimeout(() => {
          this.showError = false;
          this.errorMsg = "";
        }, 3000);
      }
    },

    async getProductDiamondDetail() {
      try {
        this.cardLoadingDetailBerlian = true;

        const diamondDetail = await dataService.getProductDiamondDetail();

        this.dataDetailBerlian = diamondDetail.data.data;
        this.cardLoadingDetailBerlian = false;
      } catch (error) {
        this.showError = true;
        this.errorMsg = error.message;

        setTimeout(() => {
          this.showError = false;
          this.errorMsg = "";
        }, 3000);
      }
    },

    async getProductGoldDetail() {
      try {
        this.cardLoadingDetailEmas = true;

        const goldDetail = await dataService.getProductGoldDetail();

        this.dataDetailEmas = goldDetail.data.data;
        this.cardLoadingDetailEmas = false;
      } catch (error) {
        this.showError = true;
        this.errorMsg = error.message;

        setTimeout(() => {
          this.showError = false;
          this.errorMsg = "";
        }, 3000);
      }
    },

    parseRupiah(price) {
      if (price == null) {
        return "";
      } else {
        return "Rp. " + price.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.");
      }
    },

    async getCabang() {
      this.cabangLoading = true;

      await dataService
        .getCabang()
        .then((res) => {
          this.dataCabang = res.data;
          this.cabangLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            location.reload();
          }
        });
    },
  },
  created() {
    this.getProductSoldNew();
    this.getProductSoldLost();
    this.getProductDiamondDetail();
    this.getProductGoldDetail();
    this.getCabang();
  },
};
</script>

<style scoped>
.clickable:hover {
  cursor: pointer;
}
.btn {
  text-transform: none;
}
.btnWeigth {
  font-weight: 900;
}
.myHeight {
  min-height: 100vh;
}
</style>
