<template>
  <section class="section-container">
    <v-alert v-if="errorMsg" type="error" class="myFixed" width="100vw">
      {{ errorMsg }}
    </v-alert>
    <v-row class="signin">
      <v-col cols="6" class="d-flex justify-center align-center myPrimary">
        <div>
          <img src="@/assets/LoginAssets.svg" alt="loginassets" />
          <div class="text-center white--text">Membantu Menjaga Stok Barang</div>
        </div>
      </v-col>
      <v-col cols="6" class="d-flex flex-column justify-center px-16">
        <div class="mb-8">
          <div class="mb-8">
            <img src="@/assets/LogoSinggalang.svg" alt="logoassets" />
          </div>
          <div class="mb-2 myPrimary--text text-h4 font-weight-medium">Masuk</div>
          <div class="myThird--text text-body-2 font-weight-light">Manajemen Barang Toko</div>
        </div>
        <validation-observer ref="observer" class="mb-4">
          <v-form @submit.prevent="submit">
            <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
              <div class="pl-1 font-weight-medium myPrimary--text text-body-1 mb-1">Email</div>
              <v-text-field
                v-model="email"
                :error-messages="errors"
                placeholder="Masukkan E-Mail Anda (Pusat/Cabang)"
                required
                outlined
                dense
                solo
                class="rounded-xl"
              ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="Password" rules="required">
              <div class="pl-1 font-weight-medium myPrimary--text text-body-1 mb-1">Kata Sandi</div>
              <v-text-field
                v-model="password"
                :error-messages="errors"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                placeholder="Masukkan Kata Sandi Anda"
                required
                outlined
                dense
                solo
                :type="showPass ? 'text' : 'password'"
                class="rounded-xl"
              ></v-text-field>
            </validation-provider>
            <div class="d-flex items-center justify-space-between mb-8">
              <v-checkbox
                v-model="checkbox"
                label="Ingat Saya"
                color="myPrimary"
                class="ma-0 pa-0 black--text"
              ></v-checkbox>
              <router-link class="font-weight-medium mySecondary--text myLink" to="/forgotpassword"
                >Lupa Password?</router-link
              >
            </div>
            <div class="text-center">
              <v-btn class="myPrimary white--text btn" type="submit" rounded block> Masuk </v-btn>
            </div>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </section>
</template>

<script>
// API
import authService from "../../services/auth.service";

import { required, email } from "vee-validate/dist/rules";
import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from "vee-validate";
import { mapActions } from "vuex";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    email: "",
    password: null,
    showPass: false,
    checkbox: true,
    errorMsg: "",
  }),
  computed: {
    params() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions(["SET_AUTH"]),
    async submit() {
      const data = {
        email: this.email,
        password: this.password,
      };
      const valid = await this.$refs.observer.validate();
      if (valid) {
        await authService
          .login(data)
          .then((res) => {
            localStorage.setItem("token", res.data.data.token);
            this.errorMsg = "";
            this.$router.push("/dashboard");
          })
          .catch(() => {
            this.errorMsg = "invalid email/password, please try again.";
          });
      }
    },
  },
};
</script>

<style scoped>
.myFixed {
  position: fixed;
}

.section-container {
  background: #fff;
  width: 100%;
  box-sizing: border-box;
}

.signin {
  padding: 0;
  margin: 0 auto;
  min-height: 100vh;
}

.black--text /deep/ label {
  color: #144444;
  font-weight: 500;
}

.btn {
  text-transform: none;
}

.myLink {
  text-decoration: none;
}
</style>
