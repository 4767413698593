<template>
  <TemplateHeader :header="'Dashboard'" :withBackButton="true" :to="'/dashboard'" :branch_name="branch_name">
    <v-row>
      <v-col cols="12" md="9">
        <div class="font-weight-medium text-body-1">
          Statistik Data <span class="red--text">Cabang</span> {{ branch_name }}
        </div>
        <v-row class="mt-8">
          <v-col cols="6" md="3" class="clickable" @click="toSold">
            <InfoSmallCard
              :header="'Produk Terjual'"
              :quantity="detailData.produk_terjual"
              :satuan="'Produk'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toVerification">
            <InfoSmallCard
              :header="'Produk Hilang'"
              :quantity="detailData.produk_hilang"
              :satuan="'Produk'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toBerlianTerjual">
            <InfoSmallCard
              :header="'Berlian Terjual'"
              :quantity="detailData.berlian_terjual"
              :satuan="'Produk'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toEmasTerjual">
            <InfoSmallCard
              :header="'Emas Terjual'"
              :quantity="detailData.emas_terjual"
              :satuan="'gram'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toBerlianTersedia">
            <InfoSmallCard
              :header="'Stok Berlian'"
              :quantity="detailData.berlian_tersedia"
              :satuan="'Produk'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3" class="clickable" @click="toEmasTersedia">
            <InfoSmallCard
              :header="'Stok Emas'"
              :quantity="detailData.emas_tersedia"
              :satuan="'gram'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Keuntungan Emas'"
              :quantity="detailData.keuntungan_emas"
              :satuan="'gram'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Keuntungan Berlian'"
              :quantity="parseRupiah(detailData.keuntungan_berlian)"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Emas Ikat Berlian'"
              :quantity="detailData.berat_emas_terikat"
              :satuan="'gram'"
              :loading="cardLoading"
            />
          </v-col>
          <v-col cols="6" md="3">
            <InfoSmallCard
              :header="'Harga Berlian Terikat'"
              :quantity="parseRupiah(detailData.harga_berlian_terikat)"
              :satuan="''"
              :loading="cardLoading"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card height="auto" :loading="cardLoading">
              <div class="pa-4">
                <div class="d-flex justify-space-between align-center mb-4">
                  <div>Pesanan Terbaru</div>
                  <div>
                    <v-btn :to="`/sold/${id}`" small color="mySecondary white--text btn"> Lihat Lainnya </v-btn>
                  </div>
                </div>
                <div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Tanggal</th>
                          <th class="text-left">RFID</th>
                          <th class="text-left">Kode Barang</th>
                          <th class="text-left">Nama Produk</th>
                          <th class="text-left">Cabang</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in detailData.produk_terjual_terbaru" :key="item.rfid">
                          <td>{{ item.date_order }}</td>
                          <td>{{ item.rfid }}</td>
                          <td>{{ item.sku }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.branch.branch_name }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="3">
        <ListSubCabangCard />
      </v-col>
    </v-row>
  </TemplateHeader>
</template>

<script>
import TemplateHeader from "../../../layouts/TemplateHeader.vue";
import InfoSmallCard from "../../../components/Card/InfoSmallCard.vue";
import ListSubCabangCard from "../../../components/Card/ListSubCabangCard.vue";

import dataService from "../../../services/data.service";

export default {
  components: {
    InfoSmallCard,
    TemplateHeader,
    ListSubCabangCard,
  },
  data() {
    return {
      cardLoading: false,
      cabangLoading: false,

      detailData: {},
      branch_name: "",
      id: this.$route.params.id,
    };
  },
  methods: {
    async getCabang() {
      const id = this.$route.params.id;

      await dataService.getCabang().then((res) => {
        const findCabang = res.data.find((data) => data.id == id);

        this.branch_name = findCabang.branch_name;
        this.cabangLoading = false;
      });
    },
    async getProductsSoldBranchDetail() {
      this.cardLoading = true;

      const id = this.$route.params.id;

      await dataService.getProductSoldBranchDetail(id).then((res) => {
        this.detailData = res.data;
        this.cardLoading = false;
      });
    },
    toSold() {
      this.$router.push("/sold/" + this.id);
    },
    toVerification() {
      this.$router.push("/verification");
    },
    toEmasTerjual() {
      this.$router.push("/sold-gold/" + this.id);
    },
    toBerlianTerjual() {
      this.$router.push("/sold-diamond/" + this.id);
    },
    toEmasTersedia() {
      this.$router.push("/stock-gold/" + this.id);
    },
    toBerlianTersedia() {
      this.$router.push("/stock-diamond/" + this.id);
    },
    parseRupiah(price) {
      if (price == null) {
        return 0;
      } else {
        return "Rp. " + price.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.");
      }
    },
  },
  created() {
    this.getProductsSoldBranchDetail();
    this.getCabang();
  },
};
</script>

<style scoped>
.clickable:hover {
  cursor: pointer;
}
</style>
