import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1124,
      xl: 1300,
    },
  },
  theme: {
    themes: {
      light: {
        myPrimary: "#483952",
        mySecondary: "#261C2D",
        myThird: "#7092BD",
        myBrokenWhite: "#fafafa",
        myRed: "#D7263D",
      },
    },
  },
});
